.appBottomMenu a {
  font-size: 0.8em !important;
  background-color: #fafafa !important;
  padding-top: 5px;
  padding-bottom: 10px;
}
.appBottomMenu .item .col i {
  color: #585858;
}
.appBottomMenu .item.active:before .col i {
  background: #400a36;
  color: #400a36;
}
.appBottomMenu .active .col i {
  color: #400a36 !important;
}

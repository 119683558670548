.d-block {
  width: 100% !important;
  height: 220px !important;
}

.dashboard-menu {
  /* height: 40vh; */
  background-color: white;
  margin-top: 5px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  -webkit-box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.75);
  padding-bottom: 150px;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.winder {
  -webkit-box-shadow: 7px -9px 2px -2px rgba(255, 173, 173, 1) !important;
  -moz-box-shadow: 7px -9px 2px -2px rgba(255, 173, 173, 1) !important;
  box-shadow: 7px -9px 2px -2px rgba(255, 173, 173, 1) !important;
}
.winder-bar {
  -webkit-box-shadow: 7px -9px 2px -2px rgba(209, 208, 202, 1) !important;
  -moz-box-shadow: 7px -9px 2px -2px rgba(209, 208, 202, 1) !important;
  box-shadow: 7px -9px 2px -2px rgba(209, 208, 202, 1) !important;
}

body {
  background-color: white !important;
}
.number {
  text-align: center !important;
  justify-content: center;
  vertical-align: middle !important;
  padding-top: 29px !important;
  height: 90px !important;
  width: 70px !important;
  border-radius: 8px !important;
  border: 3px solid #400a36;
  margin: 5px;
  font-size: 75px;
  font-family: "Impact";
}
.count-reward {
  width: auto;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #400a36;
  color: aliceblue;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  float: right;
  text-align: center;
}
.rand-box {
  background-color: #400a36;
}

.hover-title:hover::after {
  content: attr(title);
  color: #400a36;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #f2f2f2;
  padding: 2px;
  border-radius: 2px;
  font-size: small;
}

@media only screen and (max-width: 900px) and (min-width: 400px)  {
  #logo {
    margin-top: 30px!important;
    margin-bottom: 30px!important;
  }
  #ladingScreen{
    margin-top: 30px!important;
    margin-bottom: 300px!important;
  }
}
